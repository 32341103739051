<template>
    <div class="img_box_s">
        <div class="title_s" v-if="type">续签无需线上申请，购物免费获赠资格后，直接联系客服处理</div>
        <img src="../../assets/20240626-110619.png"/>
    </div>
</template>
<script>
export default {
  data() {
    return {
      type:''
    };
  },
  created() {
    document.title = "";
    this.type = this.$route.query.type
  }
};
</script>
<style>
    .img_box_s{
        width: 750px;height: 100vh;
        position: relative;
    }
    .img_box_s img{
        width: 100%;height: 100vh
    }
    .img_box_s .title_s{
        color: skyblue;
        font-size: 22px;
        text-align: center;
        width: 750px;
        position: absolute;
        top: 100px;
    }
</style>
